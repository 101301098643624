import React from "react";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { PingIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { IPrivateConnections, ITestsResult } from "twillio-tests/core/TestResults";
import { useTranslation } from "react-i18next";
interface IPrivateConnectionsTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
}

const initialValues = {
  error: undefined,
  domains: {},
};

const PrivateConnectionsTest = (props: IPrivateConnectionsTestProps) => {
  const { mainColor, testResult, theme, knowledgeUrl, estimateTime, testName } = props;
  const privateConnections: IPrivateConnections = testResult?.privateConnections || initialValues;
  const { error, domains } = privateConnections;

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
  };
  const { t } = useTranslation(["common", "tests"]);
  return (
    <TestHeader
      icon={<PingIco />}
      title={t("tests:privateconnections.title")}
      tooltip={t("tests:privateconnections.tooltip")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--min tst-private-connections">
        <table>
          <tbody>
            {Object.keys(domains || {}).map((key, i: number) => {
              return (
                <tr key={i} id={`PCT-r-totalConnections ${i}`}>
                  <td className="tst-name">{key}</td>
                  <td id={`PCT-c-totalConnections ${i}`}>
                    <RenderValues
                      {...defaultProps}
                      value={domains[key].successfulConnections}
                      formatting={(value: number) => {
                        if (value === 0) {
                          return value.toString();
                        } else if (value === domains[key].totalConnections) {
                          return value.toString();
                        } else {
                          return `${value} of ${domains[key].totalConnections}`;
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default withTheme()(PrivateConnectionsTest);

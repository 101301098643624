import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Scrollbars from "react-custom-scrollbars";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";

interface IPasswordModalProps {
  classes: any;
  open: boolean;
  isValid: boolean;
  onSubmit(value: string): void;
  handleClose(): void;
  theme?: any;
}

const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    maxWidth: 400,
    width: "90%",
    backgroundColor: "#fff",
    padding: "20px 16px 10px 10px",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  content: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "stretch" as "stretch",
  },
  title: {
    display: "block",
    textAlign: "center" as "center",
    fontSize: 20,
    fontWeight: 600,
    padding: "0 0 10px",
    borderBottom: "1px solid #c9cbd1",
    marginBottom: 16,
    color: theme.palette.primary.main,
  },
  title_mend: {
    fontWeight: 500,
  },
  title_amazon: {
    fontWeight: 400,
  },
  field: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 3,
    width: "calc(100%-40px)",
  },
  error: {
    marginLeft: 20,
    marginBottom: 20,
    color: "red",
  },
  button: {
    minWidth: 200,
    alignSelf: "center" as "center",
  },
  close: {
    position: "absolute" as "absolute",
    bottom: "100%",
    right: 0,
    color: "#fff",
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 15,
    cursor: "pointer",
  },
});

const PasswordModal = (props: IPasswordModalProps) => {
  const [value, setValue] = useState("");

  const { classes, onSubmit, isValid } = props;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(value);
  };

  const handleChange = (event: any) => {
    setValue(event.currentTarget.value);
  };

  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <div className={classes.paper}>
        <span className={classes.close} onClick={props.handleClose}>
          Close
        </span>
        <Typography variant="h6" className={`${classes.title} ${classes[`title_${props.theme}`]}`}>
          Password
        </Typography>
        <form className={classes.content} onSubmit={handleSubmit}>
          <TextField
            autoComplete="on"
            type="password"
            autoFocus
            id="password"
            label="Password"
            variant="outlined"
            value={value}
            className={classes.field}
            onChange={handleChange}
          />
          <FormHelperText className={classes.error}>
            {isValid ? "" : "Invalid password"}
          </FormHelperText>
          <Button id="submit" type="submit" className={classes.button}>
            Start
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(PasswordModal);

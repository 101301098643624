import React from "react";
interface ILivevoxCustomComponentProps {
  moreBtnDisabled: boolean;
  hasUuidInUrl: boolean;
  handleStartTest: any;
}
const LivevoxCustomComponent = (props: ILivevoxCustomComponentProps) => {
  return (
    <div data-html2canvas-ignore className="sub-header">
      <div className="sub-header-caption">
        <div className="MainContent-container-1160">
          <img alt="bgImg" src="/livevox/voip-icon.png" />
          <h3>VoIP Qualification Test</h3>
          <p>
            To conduct a test, please enter your email address and state the problem you are
            experiencing in the reason field.
          </p>
          <a
            onClick={() => {
              if (props.moreBtnDisabled && !props.hasUuidInUrl) {
                props.handleStartTest();
              }
            }}
            className={
              props.moreBtnDisabled && !props.hasUuidInUrl
                ? "btn btn-large btn-green inner-scroll"
                : "btn btn-large btn-green inner-scroll btn-disabled"
            }
            id="qualityrtc-start-btn"
            href="javascript:void(0);"
          >
            Take the Test
          </a>
        </div>
      </div>
    </div>
  );
};

export default LivevoxCustomComponent;

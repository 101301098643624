import React from "react";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { NetworkInformationIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { INetworkInformation, ITestCall, ITestsResult } from "twillio-tests/core/TestResults";

interface INetworkInformationTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
}

const initialValues = {
  type: undefined,
  effectiveType: undefined,
  downlink: undefined,
  downlink_max: undefined,
  rtt: undefined,
  proxy: undefined,
};

const NetworkInformationTest = (props: INetworkInformationTestProps) => {
  const { mainColor, testResult, theme, colorThresholds, knowledgeUrl, estimateTime, testName } =
    props;
  const networkInfo: INetworkInformation =
    testResult && testResult.networkInformation ? testResult.networkInformation : initialValues;
  const currentThresholds = null;
  const { error, type, effectiveType, downlink, downlink_max, rtt, proxy } = networkInfo;

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: theme.customColors.successColor,
  };

  const unknown = "<span>unknown</span>";
  const netType = `${type || unknown} / ${effectiveType || unknown}`;
  const netDownload = `${downlink ? downlink.toFixed(2) : unknown} / ${
    downlink_max ? downlink_max.toFixed(2) : unknown
  } ${downlink || downlink_max ? "<span>Mbps</span>" : ""}`;

  const proxyValue = proxy ? "Yes" : "<span>None</span>";

  return (
    <TestHeader
      icon={<NetworkInformationIco />}
      title="Network Information"
      tooltip={"Network type, speed and proxy information as reported by the browsers"}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--min tst-call-quality">
        <table>
          <tbody>
            <tr className="tst-net-type" id="NIT-r-networkType">
              <td>Network type</td>
              <td id="NIT-c-networkType">
                <RenderValues {...defaultProps} value={netType} color={defaultProps.color} />
              </td>
            </tr>
            <tr className="tst-net-download" id="NIT-r-downLinkSpeed">
              <td>Downlink speed</td>
              <td id="NIT-r-downLinkSpeed">
                <RenderValues {...defaultProps} value={netDownload} color={defaultProps.color} />
              </td>
            </tr>
            <tr className="tst-net-rtt">
              <td>Round trip</td>
              <td id="NIT-c-downLinkSpeed">
                <RenderValues
                  {...defaultProps}
                  formatting={(value: number) => `${value.toFixed(0)} <span>ms</span>`}
                  value={rtt}
                  color={defaultProps.color}
                />
              </td>
            </tr>
            <tr className="tst-net-proxy" id="NIT-r-HTTPProxy">
              <td>HTTP Proxy</td>
              <td id="NIT-c-HTTPProxy">
                <RenderValues {...defaultProps} value={proxyValue} color={defaultProps.color} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default withTheme()(NetworkInformationTest);

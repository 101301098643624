import React from "react";
import withTheme from "@material-ui/core/styles/withTheme";
import { ITestsResult, ICheckBrowser } from "twillio-tests/core/TestResults";
import { RenderValues } from "../helpers/utils";

interface IBrowserCanRunProps {
  testResult?: ITestsResult;
  theme?: any;
}

const initialValues: ICheckBrowser = {
  error: undefined,
  canRun: true,
  browser: undefined,
  version: undefined,
  supportsUnifiedPlan: undefined,
};

const BrowserCanRunTest = (props: IBrowserCanRunProps) => {
  const { testResult, theme } = props;

  const checkBrowser = testResult?.checkBrowser ? testResult.checkBrowser : initialValues;

  const defaultProps = {
    color: theme.customColors.errorColor,
  };

  if (checkBrowser?.canRun) {
    return null;
  }

  return (
    <>
      {testResult && (
        <div className="tests-card-content tests-card-content--min additional-tests-card-content tst-browser-can-run-tests">
          <span id="BCRT-s-browser" style={{ fontWeight: 600, fontSize: 14 }}>
            {`Browser not supported. Browser: ${checkBrowser.browser}. Version: ${checkBrowser.version}`}
          </span>
        </div>
      )}
    </>
  );
};

export default withTheme()(BrowserCanRunTest);

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { BandwidthIco, ChevronIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { ISpeed, ISpeedAll, ITestsResult } from "twillio-tests/core/TestResults";

const styles = (theme: any) => ({
  slider: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0",
    fontSize: 14,
    fontWeight: 400,
  },
  prev: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    textTransform: "capitalize" as "capitalize",
    "& svg": {
      width: 5,
      height: 8,
      transform: "scale(-1)",
      marginRight: 5,
    },
  },
  next: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    textTransform: "capitalize" as "capitalize",
    "& svg": {
      width: 5,
      height: 8,
      marginLeft: 5,
    },
  },
  disabled: {
    pointerEvents: "none" as "none",
    opacity: 0.5,
  },
});

const Mbps = (value: string | number) => `${formatNumber(Number(value))} <span>Mbps</span>`;

interface IBandwidthTestAllProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  classes?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  onlyOneItem?: boolean;
  testName?: string;
}

const initialValues: ISpeed = {
  download: undefined,
  jitter: undefined,
  upload: undefined,
  region: undefined,
};

const BandwidthTestAll = (props: IBandwidthTestAllProps) => {
  const {
    mainColor,
    testResult,
    theme,
    classes,
    colorThresholds,
    knowledgeUrl,
    estimateTime,
    onlyOneItem,
    testName,
  } = props;
  const speedTest: ISpeedAll =
    testResult && testResult.speedTest ? testResult.speedTest.all : [initialValues];
  const currentThresholds = colorThresholds?.BandwidthSpeed || null;

  const [currentSlide, updateSlide] = useState(0);

  const error = (speedTest as any).error;
  const currentTest: ISpeed = speedTest[currentSlide];

  const { download, jitter, upload, region } = currentTest || initialValues;

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: theme.customColors.successColor,
  };
  const isFirstSlide = currentSlide === 0;
  const isLastSlide = currentSlide === (speedTest as any).length - 1;
  const { t } = useTranslation(["common", "tests"]);
  return (
    <TestHeader
      icon={<BandwidthIco />}
      title={t("tests:bandwidth.title", { items: !onlyOneItem ? " (2)" : "" })}
      tooltip={t("tests:bandwidth.tooltip")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--min tst-bandwidth">
        {!onlyOneItem && (
          <div className={classes.slider}>
            <span
              className={`${classes.prev} ${isFirstSlide ? classes.disabled : ""}`}
              onClick={() => updateSlide(currentSlide - 1)}
            >
              <ChevronIco />
              {t("previous")}
            </span>
            <span className={classes.name}>{region}</span>
            <span
              className={`${classes.next} ${isLastSlide ? classes.disabled : ""}`}
              onClick={() => updateSlide(currentSlide + 1)}
            >
              {t("next")}
              <ChevronIco />
            </span>
          </div>
        )}
        <table>
          <tbody>
            <tr className="tst-uplink" id="BWTA-r-uplink">
              <td>{t("uplink")}</td>
              <td id="BWTA-c-upload">
                <RenderValues
                  {...defaultProps}
                  value={upload}
                  formatting={Mbps}
                  color={
                    currentThresholds && currentThresholds.upload
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: upload,
                            warningValue: currentThresholds.upload.warning,
                            errorValue: currentThresholds.upload.error,
                          },
                          currentThresholds.upload.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr className="tst-downlink" id="BWTA-r-downlink">
              <td>{t("downlink")}</td>
              <td id="BWTA-c-downlink">
                <RenderValues
                  {...defaultProps}
                  value={download}
                  formatting={Mbps}
                  color={
                    currentThresholds && currentThresholds.download
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: download,
                            warningValue: currentThresholds.download.warning,
                            errorValue: currentThresholds.download.error,
                          },
                          currentThresholds.download.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr className="tst-jitter" id="BWTA-r-jitter">
              <td>{t("jitter")}</td>
              <td id="BWTA-c-jitter">
                <RenderValues
                  {...defaultProps}
                  value={jitter}
                  color={
                    currentThresholds && currentThresholds.jitter
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: jitter,
                            warningValue: currentThresholds.jitter.warning,
                            errorValue: currentThresholds.jitter.error,
                          },
                          currentThresholds.jitter.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(BandwidthTestAll));

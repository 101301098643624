import React from "react";
import withTheme from "@material-ui/core/styles/withTheme";
import { ITestsResult, IGetUserMedia } from "twillio-tests/core/TestResults";
import * as _ from "lodash";

interface IGetUserMediaProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  config?: any;
}

const GetUserMediaTest = (props: IGetUserMediaProps) => {
  const { mainColor, testResult, theme, config } = props;

  const getUserMedia: IGetUserMedia | undefined = testResult?.getUserMedia;

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    errorColor: theme.customColors.errorColor,
  };

  const error = getUserMedia?.error;

  if (!getUserMedia) {
    return null;
  }

  return (
    <>
      {error && (
        <div className="tests-card-content tests-card-content--min additional-tests-card-content tst-get-user-media">
          {_.isArray(error) ? (
            <>
              {error.map((e) => (
                <div key={e} style={{ color: defaultProps.errorColor }}>
                  {e}
                </div>
              ))}
            </>
          ) : (
            <div style={{ color: defaultProps.errorColor }}>{error}</div>
          )}
        </div>
      )}
    </>
  );
};

export default withTheme()(GetUserMediaTest);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { ITestsResult } from "twillio-tests/core/TestResults";
import { customTranslateText, translateText } from "../../helpers/utils";
import checkBrowser from "../../actions/browserCheck";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { useTranslation } from "react-i18next";
interface IPermissionModalProps {
  classes?: any;
  testResult?: ITestsResult | undefined;
  theme?: any;
  textDictionary?: Record<string, string>;
  config?: TestConfiguration;
  open: boolean;
}

function mapStateToProps(state: any) {
  return {
    testResult: state.tests.testResult,
    theme: state.document.theme,
    textDictionary: state.tests.textDictionary,
    config: state.tests.config,
  };
}

const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    maxWidth: 400,
    width: "90%",
    backgroundColor: "#fff",
    padding: "30px",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  message: {
    textAlign: "center" as "center",
  },
  close: {
    position: "absolute" as "absolute",
    bottom: "100%",
    right: 0,
    color: "#fff",
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 15,
    cursor: "pointer",
  },
  close_mend: {
    fontWeight: 500,
  },
});

const PermissionModal = (props: IPermissionModalProps) => {
  const { classes, textDictionary, theme, config, open } = props;
  let checkAudio = true;
  let checkVideo = true;
  if (!textDictionary) {
    throw new Error("Missing parameter");
  }
  const { t } = useTranslation(["common", "tests"]);
  if (config && config.getUserMedia) {
    checkAudio = config.getUserMedia.checkAudio ?? false;
    checkVideo = config.getUserMedia.checkVideo ?? false;
  }

  const message = `${t("permission_status_message")} ${
    checkVideo && checkAudio
      ? `${t("camera")} and ${t("microphone")}`
      : checkVideo
      ? t("camera")
      : checkAudio
      ? t("microphone")
      : ""
  }`;
  return (
    <Modal open={open}>
      <div className={classes.paper}>
        <div className={`${classes.cont}`}>
          <span className={`${classes.text} ${classes[`text_${theme}`]}`}> {message}</span>
        </div>
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, null)(withStyles(styles)(PermissionModal));

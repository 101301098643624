import { ActionType } from "../actions/actionTypes";
import { IDocumentState } from "../interfaces";
import { TestNames } from "twillio-tests/config";

const documentInitialState: IDocumentState = {
  isLoading: true,
  theme: null,
  testsCount: 0,
  currentTestCount: 0,
  currentTestsTime: null,
  testIsLoading: false,
  layout: {
    header: "",
    footer: "",
  },
  uuid: null,
  viewMode: false,
  colorThresholds: null,
  fields: [],
  sendEmailStatus: null,
  customError: null,
};

export default (state: IDocumentState = documentInitialState, action: any) => {
  switch (action.type) {
    case ActionType.START_PRELOAD:
      return { ...state, isLoading: true };
    case ActionType.STOP_PRELOAD:
      return { ...state, isLoading: false };
    case ActionType.SET_CUSTOM_ERROR:
      return { ...state, customError: action.payload };
    case ActionType.GET_LAYOUT: {
      const {
        header,
        footer,
        config,
        uuid,
        viewMode,
        backgroundColor,
        explanationMessageColor,
        hidePoweredByRTC,
        emailRequiredForStart,
        useTextAsEmail,
        reasonFieldValidation,
        report,
      } = action.payload;
      const testList = config.testsList;
      return {
        ...state,
        fields: config.fields,
        uuid,
        viewMode,
        layout: {
          header,
          footer,
          backgroundColor,
          explanationMessageColor,
          hidePoweredByRTC,
          emailRequiredForStart,
          useTextAsEmail,
          reasonFieldValidation,
          report,
        },
        theme: config.theme,
        testsCount: testList.length,
      };
    }
    case ActionType.UPDATE_SEND_EMAIL_STATUS:
      return { ...state, sendEmailStatus: action.status };
    case ActionType.START_TESTS:
      return { ...state, testIsLoading: true };
    case ActionType.STOP_TESTS:
      return { ...state, testIsLoading: false, currentTestCount: 0 };
    case ActionType.FINISH_ONE_TEST: {
      const count = state.currentTestCount + action.payload.id ? 1 : 0;
      const currentTestsTime = action.payload;
      return { ...state, currentTestCount: count, currentTestsTime };
    }
    case ActionType.GET_UUID:
      return { ...state, uuid: action.payload };
    case ActionType.UPDATE_COLOR_THRESHOLDS:
      return { ...state, colorThresholds: action.payload };
    default:
      return state;
  }
};

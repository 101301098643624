import * as React from "react";
import LogModal from ".";

interface ILogModalContext {
  open: boolean;
  setOpen: (flag: boolean, anchor?: string) => void;
}

export const LogModalContext = React.createContext<ILogModalContext>({} as ILogModalContext);

export const LogModalProvider = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const [anchor, setAnchor] = React.useState("");

  const handleSetOpen = (flag: boolean, anchor = "") => {
    setOpen(flag);
    setAnchor(anchor);
  };

  return (
    <LogModalContext.Provider
      value={{
        open,
        setOpen: handleSetOpen,
      }}
    >
      {props.children}
      <LogModal open={open} setOpen={() => handleSetOpen(false)} anchor={anchor} />
    </LogModalContext.Provider>
  );
};

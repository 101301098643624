import React from "react";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { TurnConnectivityIcon } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { ITestsResult, IRCVTURNServers } from "twillio-tests/core/TestResults";

interface IRCVTURNServersTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
}

const initialValues = {
  total: undefined,
  udp: undefined,
  tcp: undefined,
  relay: undefined,
  additionalMessage: undefined,
  warnings: undefined,
};

const RCVTURNServersTest = (props: IRCVTURNServersTestProps) => {
  const { mainColor, testResult, theme, knowledgeUrl, estimateTime, testName } = props;
  const ircvturnServers: IRCVTURNServers = testResult?.rcvTurnServers
    ? testResult.rcvTurnServers
    : initialValues;

  const { total, udp, tcp, relay, additionalMessage, warnings, error } = ircvturnServers;

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: (value: number) => {
      if (value === total) {
        return "OK";
      } else {
        return `${value} of ${total}`;
      }
    },
  };
  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: theme.customColors.successColor,
  };

  const noResults = !udp && !tcp && !relay;

  const separator = <div style={{ width: 1, borderRight: "1px solid rgba(201, 203, 209, 0.5)" }} />;
  return (
    <TestHeader
      icon={<TurnConnectivityIcon />}
      title="TURN Servers"
      tooltip="TURN Servers"
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-rcv-turn-servers tst-rcv-turn-servers">
        <div className={`tests-card-rcv-turn-servers__box ${noResults ? "empty_box" : ""}`}>
          <div className="tests-card-rcv-turn-servers__item">
            <span className="tests-card-rcv-turn-servers__title">udp</span>
            <span className="tests-card-rcv-turn-servers__value" id="RCV-s-udp">
              <RenderValues
                tstId="tst-udp"
                {...defaultProps}
                value={udp}
                color={udp === total ? defaultProps.color : colorTypes.errorColor}
              />
            </span>
          </div>
          {separator}
          <div className="tests-card-rcv-turn-servers__item">
            <span className="tests-card-rcv-turn-servers__title">TCP</span>
            <span className="tests-card-rcv-turn-servers__value" id="RCV-s-tcp">
              <RenderValues
                {...defaultProps}
                tstId="tst-tcp"
                value={tcp}
                color={tcp === total ? defaultProps.color : colorTypes.errorColor}
              />
            </span>
          </div>
          {/*
          {separator}
          <div className="tests-card-rcv-turn-servers__item">
            <span className="tests-card-rcv-turn-servers__title">RELAY</span>
            <span className="tests-card-rcv-turn-servers__value">
              <RenderValues
                {...defaultProps}
                tstId="tst-relay"
                value={relay}
                color={relay === total ? defaultProps.color : colorTypes.errorColor}
              />
            </span>
          </div>
          */}
        </div>
        {!noResults && <span style={{ marginTop: 20 }}>{additionalMessage}</span>}
      </div>
    </TestHeader>
  );
};

export default withTheme()(RCVTURNServersTest);

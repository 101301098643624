import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import withTheme from "@material-ui/core/styles/withTheme";

const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    maxWidth: 400,
    width: "90%",
    backgroundColor: "#fff",
    padding: "20px 16px 10px 10px",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    minHeight: "150px",
    display: "flex",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "stretch" as "stretch",
  },
  title: {
    display: "block",
    textAlign: "center" as "center",
    fontSize: 20,
    fontWeight: 600,
    padding: "0 0 10px",
    borderBottom: "1px solid #c9cbd1",
    marginBottom: 16,
    color: theme.palette.primary.main,
  },
  title_mend: {
    fontWeight: 500,
  },
  title_amazon: {
    fontWeight: 400,
  },
  field: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 3,
    width: "calc(100%-40px)",
  },
  error: {
    margin: 0,
    width: "100%",
    textAlign: "center" as "center",
    fontSize: "1.3rem",
    color: "red",
  },
  button: {
    minWidth: 200,
    alignSelf: "center" as "center",
  },
  close: {
    position: "absolute" as "absolute",
    bottom: "100%",
    right: 0,
    color: "#fff",
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 15,
    cursor: "pointer",
  },
});

const MessageModal = (props: any) => {
  let [open] = useState(false);
  const { config } = props;

  if (config?.invalidInvite) {
    open = true;
  }

  const { classes } = props;

  return (
    <Modal open={open}>
      <div className={classes.paper}>
        {/* <span className={classes.close} onClick={props.handleClose}>
          Close
        </span> */}
        <Typography variant="h5" className={classes.error}>
          {config?.invalidInviteMessage}
        </Typography>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(withTheme()(MessageModal));

import React from "react";
import withTheme from "@material-ui/core/styles/withTheme";
import { ITestsResult, IVideoPlayback } from "twillio-tests/core/TestResults";
import { RenderValues } from "../helpers/utils";

interface IVideoPlaybackProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
}

const VideoPlaybackTest = (props: IVideoPlaybackProps) => {
  const { mainColor, testResult, theme } = props;

  const videoPlayback: IVideoPlayback | undefined = testResult?.videoPlayback;

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
  };

  const blocked = videoPlayback?.blocked ? "Blocked" : "Not blocked";
  const error = videoPlayback?.error;

  return (
    <>
      <canvas id="videoSource" style={{ display: "none" }} />
      <video id="video" style={{ display: "none" }} />
      {videoPlayback && (
        <div className="tests-card-content tests-card-content--min additional-tests-card-content tst-video-playback">
          {error && (
            <span style={{ color: defaultProps.color }} id="VPT-e-playVideo">
              {error}
            </span>
          )}
          {!error && (
            <table style={{ width: "unset" }}>
              <tbody>
                <tr className="tst-net-type" id="VPT-r-playVideo">
                  <td style={{ width: 200, border: "none" }}>Play Video</td>
                  <td style={{ border: "none" }} id="VPT-c-playVideo">
                    <RenderValues {...defaultProps} value={blocked} color={defaultProps.color} />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      )}
    </>
  );
};

export default withTheme()(VideoPlaybackTest);
